const baseUrl = window.location.origin;
const API_URL = "https://hub.coderslab.ro/api";

export default {
  API_URL,
  baseUrl,
  googleClientId:
    "37903145120-jgaafrt67dgo8tu22b65fdol9gjbddlu.apps.googleusercontent.com",
  googleClientSecret: "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",
  sentryDsn: "https://47eabff8c1f74df094be5cb74b93564a@sentry.io/1475343",
  selectedLang: "en",
};
